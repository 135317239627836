import React from 'react';
import auth, { authContext } from '../../utils/auth';

const ProvideAuth = ({ children }: any) => {
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export default ProvideAuth;
