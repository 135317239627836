import { createContext, useContext } from 'react';
import firebase from './firebase';

export const authContext = createContext<Auth>({ token: '' });

interface Auth {
    token: string;
}

let auth: Auth = {
    token: '',
};

export const checkToken = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
        console.log(await user.getIdToken());
    }
};

export const logOut = async () => firebase.auth().signOut();

export const getCurrentUser = () => firebase.auth().currentUser;

export function useAuth() {
    return useContext(authContext);
}

export default auth;
