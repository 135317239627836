import { useEffect, useState } from 'react';

import { buildClientSchema, GraphQLSchema, parse } from 'graphql';
// There are no @types helpers for this package.
// @ts-ignore
import GraphiQLExplorer from '../components/graphiql-explorer';
import GraphiQL from 'graphiql';

import '../styles/custom.graphiql-explorer.css';
import '../styles/custom.graphiql.css';

import { checkToken, getCurrentUser, logOut } from '../utils/auth';
import { useHistory } from 'react-router-dom';

const GraphQLView = () => {
    const [schema, setSchema] = useState<GraphQLSchema>();
    const [query, setQuery] = useState<any>();
    const [explorerOpen, setExplorerOpen] = useState<boolean>(true);
    const [graphiql, setGraphiql] = useState<GraphiQL>();
    const [title, setTitle] = useState<string>('');
    const [user, setUser] = useState<any>();
    const history = useHistory();

    const getSchemas = async () => {
        const schemas = await fetch('/v1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getCurrentUser()?.getIdToken()}`,
            },
            body: JSON.stringify({
                query: `fragment FullType on __Type {
                            kind
                            name
                            fields(includeDeprecated: true) {
                                name
                                args {
                                ...InputValue
                                }
                                type {
                                ...TypeRef
                                }
                                isDeprecated
                                deprecationReason
                            }
                            inputFields {
                                ...InputValue
                            }
                            interfaces {
                                ...TypeRef
                            }
                            enumValues(includeDeprecated: true) {
                                name
                                isDeprecated
                                deprecationReason
                            }
                            possibleTypes {
                                ...TypeRef
                            }
                            }
                            fragment InputValue on __InputValue {
                            name
                            type {
                                ...TypeRef
                            }
                            defaultValue
                            }
                            fragment TypeRef on __Type {
                            kind
                            name
                            ofType {
                                kind
                                name
                                ofType {
                                kind
                                name
                                ofType {
                                    kind
                                    name
                                    ofType {
                                    kind
                                    name
                                    ofType {
                                        kind
                                        name
                                        ofType {
                                        kind
                                        name
                                        ofType {
                                            kind
                                            name
                                        }
                                        }
                                    }
                                    }
                                }
                                }
                            }
                            }
                            query IntrospectionQuery {
                            __schema {
                                queryType {
                                name
                                }
                                mutationType {
                                name
                                }
                                types {
                                ...FullType
                                }
                                directives {
                                name
                                locations
                                    args {
                                        ...InputValue
                                    }
                                }
                            }
                        }`,
            }),
        }).then(r => r.json());

        setSchema(buildClientSchema(schemas.data));
    };

    const _handleInspectOperation = (
        cm: any,
        mousePos: { line: Number; ch: Number },
    ) => {
        const parsedQuery = parse(query || '');

        if (!parsedQuery) {
            console.error("Couldn't parse query document");
            return null;
        }

        const token = cm.getTokenAt(mousePos);
        const start = { line: mousePos.line, ch: token.start };
        const end = { line: mousePos.line, ch: token.end };
        const relevantMousePos = {
            start: cm.indexFromPos(start),
            end: cm.indexFromPos(end),
        };

        const position = relevantMousePos;

        const def = parsedQuery.definitions.find(definition => {
            if (!definition.loc) {
                console.log('Missing location information for definition');
                return false;
            }

            const { start, end } = definition.loc;
            return start <= position.start && end >= position.end;
        });

        if (!def) {
            console.error(
                'Unable to find definition corresponding to mouse position',
            );
            return null;
        }

        const operationKind =
            def.kind === 'OperationDefinition'
                ? def.operation
                : def.kind === 'FragmentDefinition'
                ? 'fragment'
                : 'unknown';

        const operationName =
            def.kind === 'OperationDefinition' && !!def.name
                ? def.name.value
                : def.kind === 'FragmentDefinition' && !!def.name
                ? def.name.value
                : 'unknown';

        const selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

        const el = document.querySelector(selector);
        el && el.scrollIntoView();
    };

    const _handleEditQuery = (query: any): void => setQuery(query);

    const _handleToggleExplorer = () => {
        setExplorerOpen(currentState => !currentState);
    };

    const setEnvironmentLayout = () => {
        if (window.location.hostname === 'data.creativetherapy.app') {
            setTitle('🔴 Production');
        } else if (
            window.location.hostname === 'staging-data.creativetherapy.app'
        ) {
            setTitle('🟢 Staging');
        } else if (window.location.hostname === 'localhost') {
            setTitle('⚪️ Local API (also staging)');
        }
    };

    useEffect(() => {
        getSchemas();

        setEnvironmentLayout();

        checkToken();

        setUser(() => {
            const user = getCurrentUser();
            if (user) return user;
        });
    }, []);

    return (
        <div className="h-screen flex">
            {schema && (
                <GraphiQLExplorer
                    title={title} // TODO: discern production from staging (also with colors)
                    schema={schema}
                    query={query}
                    onEdit={_handleEditQuery}
                    onRunOperation={(operationName: any) =>
                        graphiql ? graphiql.handleRunQuery(operationName) : null
                    }
                    explorerIsOpen={explorerOpen}
                    onToggleExplorer={_handleToggleExplorer}
                    //   getDefaultScalarArgValue={getDefaultScalarArgValue}
                    //   makeDefaultArg={makeDefaultArg}
                />
            )}

            <GraphiQL
                ref={ref => (ref ? setGraphiql(ref) : null)}
                fetcher={async graphQLParams => {
                    const data = await fetch('/v1', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${await getCurrentUser()?.getIdToken()}`,
                        },
                        body: JSON.stringify(graphQLParams),
                        credentials: 'same-origin',
                    })
                        .then(res => res.json())
                        .then(data => data);
                    return data;
                }}
                query={query}
                onEditQuery={_handleEditQuery}
                toolbar={{
                    additionalContent: (
                        <>
                            <button
                                className="toolbar-button"
                                title="Toggle the explorer"
                                onClick={() => {
                                    setExplorerOpen(open => !open);
                                }}
                            >
                                Explorer
                            </button>

                            {user && (
                                <div className="flex items-center ml-auto">
                                    <p className="text-sm opacity-75">
                                        {user.email}
                                    </p>

                                    <button
                                        className="toolbar-button"
                                        title="Log out"
                                        onClick={() => {
                                            logOut();
                                            history.push({
                                                pathname: '/login',
                                            });
                                        }}
                                    >
                                        Log out
                                    </button>
                                </div>
                            )}
                        </>
                    ),
                }}
            />
        </div>
    );
};

export default GraphQLView;
