import firebase from 'firebase/app';
import 'firebase/auth';

const stagingApp = '1:902875011551:web:6da30c9a59eb769a9a4c6a',
    productionApp = '1:902875011551:web:533512cdacc7c2549a4c6a';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyA88hcTwV9gbOdkB7T1mLsPSXh10A82ZPQ',
    authDomain: 'annular-primer-240012.firebaseapp.com',
    databaseURL: 'https://annular-primer-240012.firebaseio.com',
    projectId: 'annular-primer-240012',
    storageBucket: 'annular-primer-240012.appspot.com',
    messagingSenderId: '902875011551',
    appId: process.env.NODE_ENV !== 'production' ? stagingApp : productionApp,
};

firebase.initializeApp(firebaseConfig);

export default firebase;
