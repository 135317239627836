import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import './App.css';

import { PrivateRoute } from './screens/auth/PrivateRoute';
import ProvideAuth from './screens/auth/ProvideAuth';
import GraphQLView from './screens/GraphQLView';
import Login from './screens/Login';
import NotFound from './screens/NotFound';

function App() {
    return (
        <ProvideAuth>
            <Router>
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <PrivateRoute path="/v1">
                        <GraphQLView />
                    </PrivateRoute>
                    <Route path="/">
                        <Redirect to="/v1" />
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </Router>
        </ProvideAuth>
    );
}

export default App;
