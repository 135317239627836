const AppHeader = () => {
    return (
        <div className="flex items-center justify-center mb-6">
            <svg
                className="h-10 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 156.06 158.26"
            >
                <path
                    d="M76.38,57.05l-.09,1.13a39.45,39.45,0,0,0,6,23.91,40.6,40.6,0,0,0-6.8-1c-.86,0-1.73-.08-2.59-.08A39.66,39.66,0,0,0,45,92.38l-.11.1-.1.11-.61.66h0l0-.86a31.66,31.66,0,0,1,.06-3.6A34.28,34.28,0,0,1,76.38,57.05m1.72-6A40.27,40.27,0,0,0,38.16,88.38a38.66,38.66,0,0,0-.06,4.27l.09,1.77a6.16,6.16,0,0,0,5.63,4.82h.41a6.45,6.45,0,0,0,3-.76,3.34,3.34,0,0,0,.66-.45l.22-.22,1.08-1.17A33.88,33.88,0,0,1,72.93,87c.73,0,1.47,0,2.2.07a33.82,33.82,0,0,1,17.16,6,3.07,3.07,0,0,0,1.79.57c1.91,0,3.75-1.73,3-4.77,0,0,0-.05,0-.06A33.62,33.62,0,0,1,82.28,58.58a31.8,31.8,0,0,1,.49-3.83A3.12,3.12,0,0,0,79.83,51c-.58,0-1.16,0-1.73,0Z"
                />
                <path
                    d="M116.12,97.86c-.53,0-1.06,0-1.6,0h-.61l-.34,0a36.9,36.9,0,0,1-18.83-6.64l-.33-.24L94,90.6A36.79,36.79,0,0,1,79,58.36a35.24,35.24,0,0,1,.54-4.28.24.24,0,0,1,.12,0,.12.12,0,0,1,.1.05,36.56,36.56,0,0,0,28.29,16.46c.26,0,.47,0,.68,0H109c.51,0,1,0,1.57,0A36.66,36.66,0,0,0,144.6,47.74l.16-.4c.07-.17.13-.29.17-.41a3.08,3.08,0,0,1,2.69-1.55h.2a3.08,3.08,0,0,1,2.51,1.55,3.19,3.19,0,0,1,.26.61l.06.14A36.73,36.73,0,0,1,153,63.28c0,.66-.11,1.32-.19,2l.18-5-.19,5.12a36.61,36.61,0,0,1-36.66,32.47Z"
                />
                <path
                    d="M147.62,48.38c.06,0,.08,0,.1,0l0,0,0,.15.05.14a34,34,0,0,1-31.72,46.11q-.74,0-1.47,0h-.87A34,34,0,0,1,96.5,88.72l-.32-.24,0,0,0,0-.28-.21,0,0,0,0A33.78,33.78,0,0,1,82,61.57a39.5,39.5,0,0,0,25.91,12h.07c.27,0,.56,0,.83,0h.16q.81,0,1.62,0a39.64,39.64,0,0,0,36.82-24.74l0-.12,0-.06c0-.05.05-.11.08-.17l0-.06h0l0-.08h0m0-6a6.07,6.07,0,0,0-5.28,3,2.67,2.67,0,0,0-.22.45c0,.1-.07.19-.11.29a4.16,4.16,0,0,0-.19.45,33.88,33.88,0,0,1-31.27,21c-.49,0-1,0-1.46,0h-.32c-.16,0-.33,0-.48,0a33.62,33.62,0,0,1-26-15.12A3.13,3.13,0,0,0,79.7,51a3.48,3.48,0,0,0-2.88,1.66.24.24,0,0,0,0,.09A38.72,38.72,0,0,0,76,58.17,39.81,39.81,0,0,0,92.18,93l.47.34.36.27a39.89,39.89,0,0,0,20.37,7.19l.39,0h.63c.58,0,1.15,0,1.72,0a40,40,0,0,0,37.33-54.25,6.51,6.51,0,0,0-.54-1.21,6.1,6.1,0,0,0-4.9-3Zm5.24,20.89h0Z"
                />
                <path
                    d="M108.81,6h0c.52,0,1.06,0,1.6.06A22.62,22.62,0,0,1,109,51.26c-.37,0-.73,0-1.1,0l-.44,0A22.86,22.86,0,0,1,87.74,36.9a13.69,13.69,0,0,1-.67-2A22.84,22.84,0,0,1,94,11.61,22.16,22.16,0,0,1,108.81,6m0-6A28.32,28.32,0,0,0,90.06,7.09a28.87,28.87,0,0,0-8.8,29.3,18.17,18.17,0,0,0,1,2.86A28.87,28.87,0,0,0,107,57.2l.55,0,1.39,0A28.63,28.63,0,0,0,110.81.07c-.67,0-1.34-.07-2-.07Zm25.75,30.34h0Z"
                />
                <path
                    d="M40,144c-.59,0-1.18,0-1.76,0A37.18,37.18,0,0,1,3,107.92,35.06,35.06,0,0,1,3.11,104l.13-1.46a3.18,3.18,0,0,1,2.94-2.18h0a3.55,3.55,0,0,1,1.79.48l.08.08,1,1.18.09.09.07.09.08.08A37,37,0,0,0,36,113.8h1a36.68,36.68,0,0,0,19-5.89.28.28,0,0,1,.11.13.12.12,0,0,1-.06.13,36.63,36.63,0,0,0-16.11,31.43,34,34,0,0,0,.37,4.21.17.17,0,0,1,0,.1.12.12,0,0,1-.09,0Z"
                />
                <path
                    d="M6.21,103.37l.5.59.17.2.07.07,0,0,.16.16A40,40,0,0,0,36,116.8h1.07a40,40,0,0,0,6.85-.78,39.64,39.64,0,0,0-7,23.66c0,.38,0,.76.05,1.14a34.18,34.18,0,0,1-31-33,33.4,33.4,0,0,1,.07-3.55l.09-.91,0,0m0-6H6.1A6.19,6.19,0,0,0,.28,102l-.16,1.78A38.68,38.68,0,0,0,0,108,40.27,40.27,0,0,0,38.13,146.9c.63,0,1.26,0,1.9,0h.17a3.14,3.14,0,0,0,3.08-3.59,32.21,32.21,0,0,1-.34-3.85,33.63,33.63,0,0,1,14.8-28.87c2.25-1.52,1.88-5.47-2.25-6a33.83,33.83,0,0,1-18.58,6.16H36a33.88,33.88,0,0,1-24.52-10.5l-.21-.23L10.33,99l-.26-.26a2.9,2.9,0,0,0-.6-.44,6.46,6.46,0,0,0-3.23-.88Z"
                />
                <path
                    d="M76.07,104.54h.72a34,34,0,0,1,31.28,47.34L108,152l-.05.15v0l0,.05h-.11l0-.07v0l0,0c0-.1-.07-.2-.1-.29l0-.09,0-.09a39.85,39.85,0,0,0-37.35-26.09h0a4.61,4.61,0,0,0-.53,0h-.53a39.42,39.42,0,0,0-26.38,11A33.82,33.82,0,0,1,57.6,110.44l0,0,.06,0,.21-.14,0,0,0,0,.24-.16a34,34,0,0,1,17.55-5.47h.29m0-6h-.44A39.85,39.85,0,0,0,54.93,105l-.27.18-.43.28a39.85,39.85,0,0,0-17.54,34.21,38.71,38.71,0,0,0,.41,4.61A3.12,3.12,0,0,0,40.21,147a3.82,3.82,0,0,0,2-.59.24.24,0,0,0,.09-.08,33.6,33.6,0,0,1,27-14.73h.94a33.84,33.84,0,0,1,31.71,22.15c.06.18.13.36.2.55l.1.29a3.76,3.76,0,0,0,.2.46,6,6,0,0,0,5.08,3.24H108a6.11,6.11,0,0,0,5-2.85,7,7,0,0,0,.56-1.15,40,40,0,0,0-36.81-55.7h-.71Z"
                />
            </svg>

            <span className="text-2xl font-semibold">Some data</span>
        </div>
    );
}

export default AppHeader;
