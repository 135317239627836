import { useEffect, useState } from "react";

const Joke = () => {
    const [joke, setJoke] = useState<string>('');

    useEffect(() => {
        const headers = new Headers();
           headers.append('Accept', 'text/plain')
           fetch('https://icanhazdadjoke.com/', {
               headers
           }).then(r => r.text()).then(d => {
               setJoke(d);
           });

    }, []);

    return (
        <p className="text-sm leading-tight mt-6 opacity-25 px-8 text-center">
            { joke }
        </p>
    );
}

export default Joke;
