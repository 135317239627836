import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppHeader from '../components/AppHeader';
import Joke from '../components/Joke';

import auth, { getCurrentUser } from '../utils/auth';
import firebase from '../utils/firebase';

const Login = () => {
    // All the 'light' colors from the tailwind theme.
    const lightColors: string[] = [
        '#b4d7ff',
        '#ead9ff',
        '#ffeda5',
        '#ffb5b5',
        '#f4c6ff',
        '#ffc7db',
        '#ffd4a2',
        '#c0ebc6',
    ];
    const [color, setColor] = useState<string>(
        lightColors[Math.floor(Math.random() * lightColors.length)],
    );
    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
    });

    const [restoringAuth, setRestoringAuth] = useState(true);

    const history = useHistory();

    const getUserRole = async (uid: string) => {
        return fetch('/v1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getCurrentUser()?.getIdToken()}`,
            },
            body: JSON.stringify({
                query: `{
                    getUserByUUID(uuid: "${uid}") {
                        role {
                            name
                        }
                    }
                }`,
            }),
        }).then(res => res.json());
        // .then(data => data.getUserByUUID?.role?.name);
    };

    const checkRole = (currentRole: string): boolean => {
        if (currentRole !== 'admin' && currentRole !== 'staff') {
            console.info("You don't have the permissions.");
            return false;
        } else {
            history.push({ pathname: 'v1' });
            return true;
        }
    };

    useEffect(() => {
        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
                firebase.auth().onAuthStateChanged(async state => {
                    if (state) {
                        const token = await state.getIdToken();
                        auth.token = token;

                        getUserRole(state.uid).then(role => {
                            const currentRole =
                                role.data.getUserByUUID.role.name.toLowerCase();

                            checkRole(currentRole);
                        });
                    } else {
                        setRestoringAuth(false);
                    }
                });
            });
    }, []);

    const login = (event: FormEvent) => {
        event.preventDefault();
        const { email, password } = credentials;

        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                    .then(async ({ user }) => {
                        if (user) {
                            const token = await user.getIdToken();
                            auth.token = token;

                            getUserRole(user.uid).then(role => {
                                const currentRole =
                                    role.data.getUserByUUID.role.name.toLowerCase();

                                checkRole(currentRole);
                            });
                        }
                    });
            });
    };

    useEffect(
        // useCallback(
        () => {
            const loopColors = setInterval(() => {
                setColor(
                    lightColors[Math.floor(Math.random() * lightColors.length)],
                );
            }, 7000);

            return () => {
                clearInterval(loopColors);
            };
            //
        },
        [],
        // )
    );

    if (restoringAuth) {
        return (
            <div className="grid place-items-center min-h-screen bg-gray-100">
                <p className="text-lg font-semibold">
                    🔐 Checking the browser...
                </p>
            </div>
        );
    } else {
        return (
            <div
                className="grid place-items-center min-h-screen duration-1000 transition-colors pt-8"
                style={{ background: color }}
            >
                <div className="max-w-md w-full mb-6">
                    <AppHeader />

                    <form
                        className="rounded-md px-12 py-8 bg-white shadow-md flex-shrink-0"
                        onSubmit={(e: FormEvent) => login(e)}
                    >
                        <div>
                            {/* [className.text-red-600.font-numbers] */}
                            <label
                                htmlFor="email"
                                className="block font-body text-lg font-bold focus-within:text-alpha-base"
                            >
                                <div>Email is required</div>
                                {/* <ng-template #emailLabel i18n="Email label@@emailLabelLogin">
                                    Email
                                </ng-template> */}

                                {/* [className.border-red-600] */}
                                <input
                                    required
                                    className="font-body block w-full text-lg text-gray-900 border-2 border-gray-400 rounded-lg px-4 py-1 focus:border-alpha-base mt-4 focus-visible:shadow-outline focus:outline-none"
                                    id="email"
                                    type="email"
                                    value={credentials.email}
                                    onInput={(e: FormEvent) => {
                                        setCredentials(c => {
                                            c.email = (
                                                e.target as HTMLInputElement
                                            ).value;
                                            return { ...c };
                                        });
                                    }}
                                />
                            </label>
                        </div>
                        <div>
                            {/* [className.text-red-600.font-numbers]=" */}
                            <label
                                className="block font-body text-lg font-bold mt-6 focus-within:text-alpha-base"
                                htmlFor="password"
                            >
                                <div>Password is required</div>
                                {/* <ng-template>
                                    Password
                                </ng-template> */}

                                {/* [className.border-red-600] */}
                                <input
                                    required
                                    className="font-body block w-full text-lg text-gray-900 border-2 border-gray-400 rounded-lg px-4 py-1 focus:border-alpha-base mt-4 focus-visible:shadow-outline focus:outline-none"
                                    id="password"
                                    type="password"
                                    value={credentials.password}
                                    onInput={(e: FormEvent) => {
                                        setCredentials(c => {
                                            c.password = (
                                                e.target as HTMLInputElement
                                            ).value;
                                            return { ...c };
                                        });
                                    }}
                                />
                            </label>
                        </div>

                        <button
                            type="submit"
                            className="block rounded-lg bg-alpha-base text-white w-full py-2 my-4 disabled:opacity-25 focus-visible:shadow-outline focus:outline-none"
                        >
                            Log in
                        </button>
                    </form>

                    <Joke />
                </div>
            </div>
        );
    }
};

export default Login;
